import styled from "@emotion/styled";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useEffect } from "react";
import { mobileUp } from "../styles/global";
import useBreakpoint from "../utils/useBreakpoint";

const Trunquee = ({ className, children }) => {
	const { isMobile } = useBreakpoint();

	const [scrollDistance, setScrollDistance] = useState(0);
	const [hovering, setHovering] = useState(false);

	let scrollWidth;
	let clipWidth;

	const trunqueeStart = (e) => {
		scrollWidth = e.target.scrollWidth;
		clipWidth = e.target.getBoundingClientRect().width;
		const diff = scrollWidth - clipWidth;

		if (scrollWidth - clipWidth > 2) {
			setScrollDistance(diff);
		}
		setHovering(true);
	};

	const variants = {
		true: {
			// transform: `translateX(-${scrollDistance}px)`,
			// x: `-${scrollDistance}`,
			// width: scrollWidth,
			x: -scrollDistance,
			overflow: "unset",
			transition: {
				// duration: `${scrollDistance / 150}s`,
				duration: scrollDistance / 100,
				// duration: 1,
			},
		},
		false: {
			x: 0,
			width: "auto",
			overflow: "hidden",
			transition: {
				duration: scrollDistance / 200,
			},
		},
	};

	const duration = scrollDistance / 15;

	return (
		<Wrap
			// onMouseOver={(e) => {
			// 	trunqueeStart(e);
			// }}
			// onMouseOver={(e) => {
			// 	if (!isMobile) {
			// 		trunqueeStart(e);
			// 	}
			// }}
			// onMouseLeave={() => {
			// 	setTimeout(() => {
			// 		setHovering(false);
			// 	}, 2000);
			// }}
			className={className}
		>
			<Inner
				scroll={scrollDistance}
				duration={duration}
				initial={false}
				animate={hovering ? "true" : "false"}
				variants={variants}
				hovering={hovering}
				className={hovering ? "hovering" : ""}
			>
				{children}
			</Inner>
		</Wrap>
	);
};

const Wrap = styled.div`
	overflow: hidden;

	> p {
		// transition-property: transform;
		// transition-duration: ${(props) => `${props.duration}ms`};
		// transition-timing-function: linear;

		@media (hover: hover) {
			:hover {
				// white-space: normal;
				// overflow: unset;
				// text-overflow: unset;
				// transform: ${(props) => `translateX(-${props.scroll}px)`};
			}
		}
	}
`;

const Inner = styled(motion.div)`
	${mobileUp} {
		p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&.hovering p {
			overflow: unset;
			// white-space: nowrap;
			// text-overflow: unset;
		}
	}
`;

export default Trunquee;
