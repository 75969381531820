import styled from "@emotion/styled";
import { css } from "@emotion/react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { mobile, mobileUp, tablet, desktop, desktopUp } from "../styles/global";
import { DateTime, Settings } from "luxon";

const Clock = ({ col, className }) => {
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);

	// useEffect(() => {
	// 	setInterval(() => {
	// 		const date = new Date();

	// 		if (date.getHours() > 12) {
	// 			setHours(((date.getHours() - 12) / 12) * 360 + "deg");
	// 		} else {
	// 			setHours((date.getHours() / 12) * 360 + "deg");
	// 		}

	// 		setMinutes((date.getMinutes() / 60) * 360 + "deg");
	// 		setSeconds((date.getSeconds() / 60) * 360 + "deg");
	// 	}, 1000);
	// }, []);

	useEffect(() => {
		setInterval(() => {
			const date = DateTime.now().setZone("Australia/Sydney");

			setHours((date.toFormat("h") / 12) * 360 + "deg");
			setMinutes((date.toFormat("m") / 60) * 360 + "deg");
			setSeconds((date.toFormat("s") / 60) * 360 + "deg");
		}, 1000);
	}, []);

	return (
		<Wrap col={col} h={hours} m={minutes} s={seconds} className={className}>
			<svg
				className={`clock-body`}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 122 122"
			>
				<path
					className="cls-1"
					d="M61,1A60,60,0,1,1,1,61,60.07,60.07,0,0,1,61,1m0-1a61,61,0,1,0,61,61A61,61,0,0,0,61,0Z"
					transform="translate(0)"
				/>
				<circle className="center" cx="61" cy="61" r="2" />
				<line
					css={css`
						transform: ${`rotate(${hours})`};
					`}
					className="hand hour"
					x1="61"
					y1="61"
					x2="61"
					y2="23"
				/>
				<line
					css={css`
						transform: ${`rotate(${minutes})`};
					`}
					className="hand min"
					x1="61"
					y1="61"
					x2="61"
					y2="10"
				/>
				<line
					css={css`
						// transition: transform 1s linear;
						transform: ${`rotate(${seconds})`};
					`}
					className="hand sec"
					x1="61"
					y1="61"
					x2="61"
					y2="10"
				/>
				<line className="marker" x1="61" y1="5" x2="61" />
				<line className="marker" x1="5" y1="61" y2="61" />
				<line className="marker" x1="61" y1="117" x2="61" y2="122" />
				<line className="marker" x1="117" y1="61" x2="122" y2="61" />
			</svg>
			{/* <ClockBody>
				<Hand></Hand>
				<Hand></Hand>
				<Hand></Hand>
				<Center></Center>
				<Marker mr={0}>-</Marker>
				<Marker mr={90}>-</Marker>
				<Marker mr={180}>-</Marker>
				<Marker mr={270}>-</Marker>
			</ClockBody> */}
		</Wrap>
	);
};

const Wrap = styled.div`
	background-color: ${(props) => props.col};
	width: 154px;
	height: 154px;
	border-radius: 8px;
	padding: 16px;

	${mobile} {
		width: 106px;
		height: 106px;
		padding: 10px;
	}

	.hand,
	.marker {
		stroke: var(--black);
	}

	.hand {
		transform-origin: center;
	}
`;

export default Clock;
