import React from "react";
import { mobile, mobileUp } from "../styles/global";
import styled from "@emotion/styled";
import Trunquee from "./Trunquee";
import { motion } from "framer-motion";
import { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import useWindow from "../utils/useWindow";
import { css } from "@emotion/react";

const SelectedAAP = ({ className, aap, activeFilter, setActiveFilter }) => {
	const allFilters = ["Award", "Activity", "Press"];
	const [activeYear, setActiveYear] = useState();
	const w = useWindow();

	useEffect(() => {
		setActiveYear();
	}, [activeFilter]);

	const wrapRef = useRef();
	const [wrapHeight, setWrapHeight] = useState(null);

	useEffect(() => {
		if (wrapRef?.current?.querySelector(".selected"))
			setWrapHeight(
				wrapRef?.current?.querySelector(".selected").getBoundingClientRect()
					.height
			);
	}, [wrapRef?.current, activeFilter, w]);

	return (
		<Wrap
			ref={wrapRef}
			css={css`
				height: ${wrapHeight}px;
			`}
		>
			{allFilters.map((filter) => {
				const AllOfType = aap.filter((el) => el.type === filter);
				const allYears = [];
				AllOfType.map((item) => {
					const year = item.date?.split("-")[0];
					allYears.push(year);
					allYears.sort();
				});
				let years = [...new Set(allYears)].reverse();
				// const min = allYears[0];
				// const max = allYears[allYears.length - 1];

				return (
					<FilterBody
						// className={`${activeFilter.title} ${filter}`}
						className={`${activeFilter.value === filter ? "selected" : ""} ${
							activeFilter.value
						} ${filter}`}
					>
						{years.map((year) => {
							const items = AllOfType.filter(
								(el) => el?.date?.split("-")[0] === year
							);

							return (
								<SingleYear key={`aap-year-${year}`}>
									<Year className="item-year">{year}</Year>
									<YearItems>
										{items.map((item) => {
											let link;
											let externalLink;
											if (item.type !== "Press") {
												externalLink = false;
												link = `/archive/#${item.slug.current}`;
											} else {
												if (item.link) {
													externalLink = true;
													link = item.link;
												} else {
													externalLink = false;
													link = `/archive/#${item.slug.current}`;
												}
											}

											return (
												<YearItem
													key={`aap-item-${item.slug.current}`}
													href={link}
													target={externalLink && "_blank"}
													rel={externalLink && "noopener noreferrer"}
												>
													{item.type === "Award" && (
														<>
															<ProjectName>{item?.project?.name}</ProjectName>
															<TruncWrapper>
																<AwardName>
																	{item?.awardName && `${item.awardName} – `}
																	{item?.awardCategory}
																</AwardName>
															</TruncWrapper>
														</>
													)}
													{item.type === "Activity" && (
														<>
															<ActivityHeadline>
																{item?.headline}
															</ActivityHeadline>
															<ActivityHost>{item?.activityHost}</ActivityHost>
															<ActivityType>{item?.activityType}</ActivityType>
														</>
													)}
													{item.type === "Press" && (
														<>
															{/* <PubHeadline>{item?.headline}</PubHeadline> */}
															<ProjectName>{item?.project?.name}</ProjectName>
															<PubTitle>{item?.publicationTitle}</PubTitle>
															{/* <PubHeadline></PubHeadline> */}
														</>
													)}
												</YearItem>
											);
										})}
									</YearItems>
								</SingleYear>
							);
						})}
					</FilterBody>
				);
			})}
		</Wrap>
	);
};

const Wrap = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	margin-bottom: var(--padding-xl);
	align-items: start;
	transition: height 500ms var(--splash-ease);
`;

const FilterBody = styled.div`
	grid-area: 1/1;
	opacity: 0;
	transition: opacity 500ms var(--splash-ease);
	pointer-events: none;

	&.selected {
		pointer-events: all;
		opacity: 1;
	}
`;

const TruncWrapper = styled(Trunquee)`
	grid-column: 5 / span 7;
`;

const SingleYear = styled.div`
	padding: var(--padding-xs) 0;
	border-bottom: var(--border-black);

	${mobileUp} {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		column-gap: var(--gap);
	}
`;

const Year = styled.p``;

const YearItems = styled.div`
	grid-column: span 11;
`;

const YearItem = styled.a`
	display: block;

	@media (hover: hover) {
		&.disabled:hover {
			opacity: 1;
			> * {
				opacity: 1;
			}
		}
	}

	${mobileUp} {
		display: grid;
		grid-template-columns: repeat(11, 1fr);
		column-gap: var(--gap);
	}
	${mobile} {
		margin-top: var(--body-line);
	}
`;

const ActivityHeadline = styled.p`
	grid-column: span 6;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const ActivityHost = styled.p`
	grid-column: span 3;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const ActivityType = styled.div`
	grid-column: span 2;
`;

const ProjectName = styled.p`
	grid-column: span 4;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const PubTitle = styled.p`
	grid-column: span 7;
`;

const AwardName = styled.p`
	grid-column: 5 / span 7;
`;

export default SelectedAAP;
